.blog-img{
    width: 100%;
/* position: relative; */
}
.donate-container{
    /* padding: 90px; */
    display:flex;
    flex-direction: column;
    gap:30px
}
.Donate-container{
    display: flex;
padding: 20px 0px 0px 70px;

/* border: 1px solid red; */


}
.donate-left{
width: 100%;
padding-top: 10%;
/* border: 1px solid red; */
text-align: justify;
}
.donate-left2{
  width: 100%;
  padding-top: 10%;
padding-right: 6%;
  /* border: 1px solid red; */
  text-align: justify;
  }
.donate-left p{
text-align: justify;
    font-weight: 200;

}
.donate-img{
    width: 99%;

    

    /* border: 1px solid red; */
}
.donate-img2{
    width: 100%;
    /* margin-top: -150px; */
    /* border: 1px solid red; */
}
.donate-left-content{
    display: flex;
    gap:30px;
}
.donate-left-icons{
    display: flex;
    gap: 10px;
font-size: 14px;
font-weight: 500;
line-height: 24px;
text-align: left;
}
.blog2-container{
    display: flex;
    flex-direction: column;
    /* gap:10px; */
    justify-content: center;
    align-items: center;
    padding: 0px 90px 0px 90px;
}
.blog2-header{
 width: 50%;
    /* border: 1px solid red; */
    justify-content: center;
    /* align-items: center; */
}
.blog2-header h2{
    text-align: center;
}
.blog2-paragraph{
  text-align: center;
  font-size: 16px;
  color: #666;
  line-height: 1.8;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  /* border:1px solid red; */
}
.testimonial-carousel {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 60px 20px;
    font-family: Arial, sans-serif;
  }
  
  .subheading {
    color: #bbb;
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .heading {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .testimonial-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .carousel-button {
    background-color: #f48fb1;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .carousel-button:hover {
    background-color: #e57373;
  }
  
  .left {
    position: absolute;
    left: 20px;
  }
  
  .right {
    position: absolute;
    right: 20px;
  }
  
  .testimonial {
    max-width: 600px;
    text-align: center;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 4px solid #f48fb1;
    margin-bottom: 20px;
  }
  
  .name {
    font-size: 20px;
    font-weight: bold;
  }
  
  .role {
    font-size: 14px;
    color: #bbb;
    margin-bottom: 20px;
  }
  
  .testimonial-text {
    font-size: 16px;
    line-height: 1.6;
    color: #ddd;
  }
  
  .dots {
    margin-top: 20px;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .dot.active {
    background-color: #f48fb1;
  }
  

  @media (min-width: 320px)and (max-width: 768px) {
    .blog-img{
      width: 100%;
    }
    .donate-container{
   display: flex;
   flex-direction: column;
    }
    .Donate-container{
      display: flex;
      flex-direction:column;
      padding: 20px;
    }
    .donate-left h2{
      font-size: 16px;
    }
    .donate-left-content{
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* border: 1px solid red; */
  /* flex-direction: column;     */
    }
    .donate-left-icons{
   width: 100%;
   /* border: 1px solid red; */
    }
    .donate-img{
      width: 100%;
      padding: 0 !important;
      margin: 0 !important;
      /* border: 1px solid red; */
    }
    .blog2-header h2{
    width: 100%;
      font-size: 16px;
      /* border: 1px solid red; */
    }
    .blog2-container{
      width: 100% !important;
      padding: 0 !important;
    }
    .blog2-paragraph{
      width: 92%;
     padding: 10px;
     /* border: 1px solid red; */
    }
    .donate-left2{
      width: 100%;
      padding-right: 0px;
      /* border: 1px solid red; */
    }
    .donate-left2 h2{
      font-size: 16px;
      /* border: 1px solid red; */
    }
    .donate-left-content{
      width: 100%;
    }
      
  .carousel-button {
/* border: 1px solid red; */
  }
  .left{
    position: absolute;
    left: 0;
bottom: 53%;
  }
  .right{
    position: absolute;
    right: 0;
bottom: 53%;
  }
}