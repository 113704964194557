.ot-first-cont img{
    width:100%;
}

.ot-second-cont,
.ot-third-cont {
    padding: 30px 50px 0 30px;
    margin-bottom: 16px;
}

.image-gallery {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
}

.image-gallery img {
    width: 322px;
    height: 444px;
    /* border:1px solid red; */
}

.members {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.diamond-members {
    width: 50%;
    /* margin: 0 auto; */
    padding: 20px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #FFFFFF;
    font-family: Arial, sans-serif;
    border-radius: 24px;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.30);
}

.ot-third-cont h4,
.ot-second-cont p {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
}

.ot-second-cont h2 {
    color: #252A34;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
    text-transform: capitalize;
    text-align: center;
}

.diamond-members h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

.diamond-members ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.diamond-members li {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    border-top: 1px solid #F0F0F0;
}

.diamond-members li:nth-child(odd) {
    background: #FFF1F1;
}

.ot-name,
.ot-amount {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
}

.other-members {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.membership-section {
    /* margin: 20px auto; */
    padding: 20px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #FFFFFF;
    font-family: Arial, sans-serif;
}

.membership-section h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

.membership-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.membership-section li {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    border-top: 1px solid #F0F0F0;
}

.membership-section li:nth-child(odd) {
    background: #FFF1F1;
}

/* Specific Background Colors */
.gold-members,
.silver-members,
.bronze-members {
    border-radius: 24px;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.30);
}

.bronze-members {
    width: 49%;
}

@media (max-width: 1024px) and (min-width: 768px) {
    .image-gallery img {
        width: 242px;
        height: 333px;
        /* border:1px solid red; */
    }
}
@media (max-width: 768px) and (min-width: 426px){
    .image-gallery img {
        width: 162px;
        height: 222px;
        /* border:1px solid red; */
    }
}
@media (max-width: 426px)  and (min-width: 320px){
    .image-gallery{
        gap:15px;
    }
    .image-gallery img {
        width: 80px;
        height:111px;
    }
    .members{
        flex-direction: column;
    }
    .bronze-members,.diamond-members,.gold-members,.silver-members,.other-members{
        width:92% !important; 
    }
    .ot-second-cont{
        padding:30px;
    }
}