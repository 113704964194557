.member-img img {
    width: 100%;
}

.mem-container {
    display: flex;
    padding: 20px 0px 0px 70px;
    /* width: 100%; */
    height: 600px;


}

.mem-left {
    width: 100%;
    padding-top: 10%;
    text-align: justify;
}

.mem-left li {
    text-align: justify;
    font-weight: 200;
    line-height: 20px;
    /* border: solid red; */
    height: 50px;
    /* gap: 23px; */

}

.become-img {
    width: 99%;
    height: auto;

}

.member1-container {
    display: flex;
    flex-direction: column;
    /* gap:10px; */
    justify-content: center;
    align-items: center;
    padding: 0px 90px 0px 90px;
}

.mem-header {
    width: 50%;
    /* border: 1px solid red; */
    justify-content: center;
    /* align-items: center; */
}

.mem-header h2 {
    text-align: center;
}

.mem-paragraph {
    text-align: center;
    font-size: 16px;
    color: #666;
    line-height: 1.8;
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
    /* border:1px solid red; */
}

.membership-info {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.membership-tables {
    display: flex;
    gap: 20px;
    flex: 1;
    justify-content: center;
    cursor: pointer;
}

.membership-table {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #FFFFFF;
    font-family: Arial, sans-serif;
    border: 1px solid #EF1F24;
    width: 40%;

}

.membership-table h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: red;
}

.membership-table ul {
    list-style: none;
    padding: 0;
}

.membership-table li {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    border-top: 1px solid #F0F0F0;
}

.membership-table li:nth-child(odd) {
    background: #FFF1F1;
}

.registered-office {
    flex: 1;
    width: 30%;
    height: 343px;
    gap: 40px;

}

.registered-office h3 {
    margin-bottom: 15px;
    color: #EF1F24;
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;
}

.registered-office p {
    /* margin-bottom: 10px; */
    font-size: 1rem;
    color: #000000;
    font-family: Satoshi;
    font-size: 18px;
    font-weight: 500;
    line-height: 32.4px;
    text-align: left;

}

.registered-office strong {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 38.4px;
    letter-spacing: 0.01em;
    text-align: center;
}

/* Base styles already provided above */

/* Responsive for 1024px and below */
@media (max-width: 1024px) {
    .mem-container {
        flex-direction: column;
        align-items: center;
        padding: 20px;
        height: auto;
    }

    .member1-container {
        padding: 0 40px;
    }
    .become-img {
        margin-left: 100px;
    }
    .mem-header h2 {
        font-size: 1.8rem;
    }

    .membership-info {
        flex-direction: column;
        gap: 15px;
    }

    .membership-table {
        width: 90%;
        /* Adjust width for better fit */
    }

    .registered-office {
        width: 90%;
        /* Adjust width */
        margin: 20px 0;
    }
}

/* Responsive for 768px and below */
@media (max-width: 768px) {
    .mem-container {
        padding: 15px;
    }

    .mem-header h2 {
        font-size: 1.5rem;
    }
    .mem-container li{
        height: auto;
    }
    .membership-table {
        width: 100%;
    }

    .registered-office h3,
    .registered-office p,
    .registered-office strong {
        font-size: 0.9rem;
    }
}

/* Responsive for 425px and below */
@media (max-width: 425px) {
    .mem-container {
        padding: 10px;
    }

    .member1-container {
        padding: 0 20px;
    }
    .mem-container li{
        height: auto;
    }
    .mem-header h2 {
        font-size: 1.2rem;
    }

    .mem-paragraph {
        font-size: 14px;
    }

    .membership-table {
        width: 100%;
        /* Full width for small screens */
    }

    .membership-table h3 {
        font-size: 1rem;
    }

    .membership-table li {
        padding: 8px 12px;
        font-size: 0.85rem;
    }

    .registered-office {
        width: 100%;
    }

    .registered-office h3,
    .registered-office p,
    .registered-office strong {
        font-size: 14px;
    }
}

/* Responsive for 320px and below */
@media (max-width: 320px) {
    .mem-header h2 {
        font-size: 1rem;
    }

    .mem-paragraph {
        font-size: 12px;
    }

    .membership-table h3 {
        font-size: 0.85rem;
    }
    .mem-container li{
        height: auto;
    }
    .membership-table li {
        padding: 6px 10px;
        font-size: 0.75rem;
    }

    .registered-office h3,
    .registered-office p,
    .registered-office strong {
        font-size: 12px;
    }
}