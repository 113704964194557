.about-us-container img {
    width: 100%;
    /* height: 300px; */
}

/* For tablets (768px) */
@media (max-width: 768px) {
    .about-us-container img {
        height: 250px;
    }
}

/* For smaller tablets and large phones (425px) */
@media (max-width: 425px) {
    .about-us-container img {
        height: 200px;
    }
}

/* For small phones (320px) */
@media (max-width: 320px) {
    .about-us-container img {
        height: 180px;
    }
}


/* *********************************************************************** */
.charity-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 50px;
    background: linear-gradient(to right, #e0f7fa, #fce4ec);
}

.text21-section {
    display: flex;
    flex-direction: column;
    padding-right: 20px;

}

.text21-section h2 {
    color: #888888;
    font-size: 1.2rem;
}

.text21-section h1 {
    font-size: 2rem;
    /* margin-top: 10px; */
    /* margin-bottom: 20px; */
}

.text21-section p {
    width: 50%;
    color: #555555;
    line-height: 1.6;
    /* border: solid red; */
}

.cards {
    display: flex;
    width: 507px;
    height: 144px;
    gap: 43px;

}

.card {
    background: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    text-align: center;
    width: 232px;
    height: 144px;
    background: #FFF6F6;


}

.card h3 {
    font-size: 1.2rem;
    color: #e74c3c;
    /* Red color */
    margin-bottom: 10px;
}

.card p {
    font-size: 0.9rem;
    color: #666666;
    width: 201px;
    height: 78px;
}

.image-section {
    flex: 1;
    position: relative;
}

.main-image {
    width: 100%;
    height: 400px;
    border-radius: 50%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.hands-image {
    width: 500px;
    height: 500px;
    gap: 0px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .charity-section {
        flex-direction: column;
        padding: 30px;
        /* width: 100%; */
    }

    .text21-section p {
        width: 100%;


        /* Make paragraph width full for readability */
    }

    .cards {
        flex-wrap: wrap;
        /* Wrap cards in smaller screens */
        gap: 20px;
    }

    .main-image {
        height: 300px;
        /* Reduce image size */
    }

    .hands-image {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 768px) {
    .text21-section h1 {
        font-size: 1.5rem;
    }

    .text21-section h2 {
        font-size: 1rem;
    }

    .main-image,
    .hands-image {
        width: 250px;
        height: 250px;
    }

    .cards {
        width: 100%;
        margin-bottom: 30px;
        /* Full width for smaller screens */
    }

    .card {
        width: 100%;
        /* Full width for individual cards */
        height: auto;
    }
}

@media (max-width: 480px) {
    .text21-section h1 {
        font-size: 1.2rem;
    }

    .text21-section h2 {
        font-size: 0.9rem;
    }

    .text21-section p {
        font-size: 0.8rem;
    }

    .main-image,
    .hands-image {
        width: 200px;
        height: 200px;
    }

    .cards {
        flex-direction: column;
        /* Stack cards vertically on small screens */
        align-items: center;
        width: 49%;
        margin-bottom: 100px;
    }

    .card h3 {
        font-size: 1rem;
    }

    .card p {
        font-size: 0.8rem;
        /* width: 50%; */
        height: 100px;
        /* Make text width full for readability */
    }
}

/* *************************************************************************************** */

.features-section {
    padding: 50px;
    text-align: center;
    background-color: #f7f7f7;
}

.features-section h2 {
    font-size: 1.2rem;
    color: #252A34;
    margin-bottom: 10px;
}

.features-section h1 {
    font-size: 2rem;
    margin-bottom: 30px;
}

.features-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.feature-card {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding: 20px;
    text-align: left;
}

.feature-icon {
    font-size: 2rem;
    color: #e74c3c;
    /* Icon color */
    margin-bottom: 15px;
}

.feature-card h3 {
    font-size: 1.2rem;
    color: #333333;
    margin-bottom: 10px;
}

.feature-card p {
    font-size: 0.9rem;
    color: #666666;
    margin-bottom: 10px;
}

.feature-card ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.feature-card ul li {
    font-size: 0.9rem;
    color: #333333;
    margin: 5px 0;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .features-section {
        padding: 40px;
    }

    .features-section h1 {
        font-size: 1.8rem;
    }

    .feature-card {
        width: 220px;
        /* Reduce width for medium screens */
    }

    .feature-card p,
    .feature-card ul li {
        font-size: 0.85rem;
    }
}

@media (max-width: 768px) {
    .features-section {
        padding: 30px;
    }

    .features-section h1 {
        font-size: 1.6rem;
    }

    .feature-card {
        width: 100%;
        /* Full width for each card on smaller screens */
        padding: 15px;
        margin-bottom: 20px;
    }

    .feature-card h3 {
        font-size: 1.1rem;
    }

    .feature-card p,
    .feature-card ul li {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .features-section {
        padding: 20px;
    }

    .features-section h1 {
        font-size: 1.4rem;
    }

    .features-section h2 {
        font-size: 1rem;
    }

    .feature-icon {
        font-size: 1.8rem;
    }

    .feature-card h3 {
        font-size: 1rem;
    }

    .feature-card p,
    .feature-card ul li {
        font-size: 0.75rem;
    }
}

/* *************************************************************** */
.gallery-section {
    padding: 40px;
    text-align: center;
}

.gallery-title {
    font-size: 2rem;
    margin-bottom: 20px;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;
}

.gallery-item {
    border-radius: 10px;
    overflow: hidden;
}

.gallery-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.view-more-button {
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.view-more-button:hover {
    background-color: #d32f2f;
}

@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .gallery-grid {
        grid-template-columns: 1fr;
    }
}