.contact-container {
    display: flex;
    flex-direction: column;
    gap: 48px;
    /* border:1px solid red; */
    /* align-items: center; */
    padding: 50px ;
    /* background: linear-gradient(to right, #F8F9FA, #F8D3FC); */
    /* border-radius: 10px; */
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
    /* width: 100%; */
    /* max-width: 800px; */
    /* margin: auto; */
}

.contact-form-container {
    display: flex;
    flex-direction: row;
    /* border:1px solid red; */
    /* gap: 40px; */
    justify-content: space-between;
}

.contact-form-container label {
    color: #252A34;
    font-family: sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 470;
    line-height: 24px;
    /* 150% */
}

.contact-form-container input {
    margin-top: 8px;
 width: 350px;
}

.contact-form-container textarea {
    width: 758px;
    margin-top: 8px;
}

.contact-header {
    font-size: 1.5em;
    /* margin-bottom: 20px; */
    color: #333;
    /* text-align: center; */
}

.contact-subheader {
    font-size: 2em;
    /* margin-bottom: 30px; */
    color: #333;
    /* text-align: center; */
    font-weight: bold;
}

.contact-form {
    display: flex;
    background: linear-gradient(135deg, #EE8C0D, #E83908);
    flex-direction: column;
    gap: 20px;
    max-width: 59%;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    /* border:1px solid red; */
    border-radius: 24px;
    border: 1px solid #F1F1F1;
    background: linear-gradient(135deg, #EE8C0D, #E83908);
    background: #FFF;
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.30);
}

.contact-form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.contact-input,
.contact-textarea {
    width: 100%;
    padding: 12px 18px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1em;
    outline: none;
    border-radius: 12px;
    border: 1px solid #EBEBEB;
    background: rgba(255, 255, 255, 0.15);
}

.contact-textarea {
    resize: vertical;
    height: 120px;
}

.contact-address {
    /* margin-top: 20px; */
    padding: 20px;
    background-color: #F5F5F5;
    border-radius: 10px;
    border-radius: 24px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.30);
    display: flex;
    width: 34%;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-height: 242px;
    max-width: 402px;
}

.contact-address h3 {
    color: #000;
    font-family: sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
}

.contact-address-details,
.contact-header-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* border:1px solid red; */
}

.contact-address-details {
    gap: 0px;
}

.contact-address p {
    margin: 5px 0;
    font-size: 1em;
    color: #555;
}

@media (max-width:1024px) and (min-width:768px){
    .contact-form{
        max-width: 53%;
    }
    .contact-form-container input{
        width:200px;
    }
    .contact-form-container textarea{
        width:455px;
    }
}
@media (max-width:768px) and (min-width:426px){
    .contact-form{
        max-width: 49%;
    }
    .contact-form-row{
        flex-direction: column;
    }
    .contact-form-container input{
        width:300px;
    }
    .contact-form-container textarea{
        width:300px;
    }
    .contact-address h3{
        margin-bottom: 0;
    }

}
@media (max-width:426px) and (min-width:320px){
    .contact-form-container{
        flex-direction: column;
        width:100% ;
        gap:10px;
    }
    .contact-form{
        /* border:1px solid red; */
        min-width:88% !important;
    }
    .contact-address{
        width:88%;
    }
    .contact-form-row{
        flex-direction: column;
    }
    .contact-form-container input{
        width:85%;
    }
    .contact-form-container textarea{
        width:85%;
    }
}