.footer {
    background-color: #333;
    color: #fff;
    align-items: center;
    padding: 40px 20px;
    display: flex;
    justify-content: space-around;
    width: 96.10%;
    /* height: 300px; */

}

.footer-container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

}

.footer-logo {
    flex: 1;
    margin: 10px;
}

.logo-img {
    max-width: 150px;
}

.footer-section {
    /* flex: 1; */
    margin: 10px;

}

.footer-section h3 {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

}

.footer-section ul {
    list-style: none;
    padding: 0;

}

.footer-section li {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #AFAFAF;

}

.footer-container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #AFAFAF;


}

.footer-section a {
    color: #AFAFAF;
    text-decoration: none;
}

.footer-section a:hover {
    text-decoration: underline;
}

.newsletter-subscription {
    display: flex;
}

.newsletter-subscription input {
    padding: 5px;
    flex: 1;
    width: 278px;
    height: 48px;

}

.newsletter-subscription button {
    background-color: #28a745;
    color: #fff;
    border: none;
    cursor: pointer;
    width: Hug (129px)px;
    height: Hug (48px)px;
    padding: 12px 24px 12px 24px;
    /* opacity: 0px; */
}


.newsletter-subscription button:hover {
    background-color: #218838;
}

.social-icons a {
    color: #fff;
    margin-right: 10px;
    font-size: 1.5em;

}

.social-icons a:hover {
    color: #bbb;
}
.footer-content1,.footer-content2{
    display: flex;
    flex-direction: row;
    /* gap:10px; */
    width:100%;
}
@media (max-width:1441px) {
    .footer-content2{
        margin-left:100px;
    }
}
@media (max-width:1024px) and (min-width:768px){
    .footer-content1{
        display: flex;
        flex-direction: column;
        /* display: none; */
    }
    .footer-content2{
        margin-left:0;
    }
}
@media (max-width: 768px)  and (min-width:426px){
    .logo-img{
        width:100px;
        height:100px;
    }
    .footer-content1{
        display: flex;
        flex-direction: column;
        /* display: none; */
    }
    .footer-section-newsletter {
        /* border:1px solid red; */
        width:42%;
    }
    .footer-section ul,.footer-section li{
        margin:0;
    }
    .footer-content2{
        margin-left:0;
    }
}
@media (max-width: 426px)  and (min-width:320px){
    .footer-container{
        flex-direction: column;
    }
    .footer-content1{
        flex-direction: column;
    }
    .footer-content2{
        margin-left:0;
    }
}
