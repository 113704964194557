.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #ffffff;
    border-bottom: 2px solid #e0e0e0;
    cursor: pointer;
}


.navbar-logo {
    display: flex;
    align-items: center;
}

.logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.logo-text {
    font-size: 18px;
    color: #d32f2f;
    font-weight: bold;
}


.navbar-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin: 0 15px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
}

.nav-item:hover {
    color: #ef5555;
}


.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px 0;
    min-width: 150px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    z-index: 1;
}

.dropdown-content li {
    padding: 8px 15px;
    cursor: pointer;
    white-space: nowrap;
}

.dropdown-content li:hover {
    background-color: #f2f2f2;
    color: #d32f2f;
}


.nav-item.dropdown:hover .dropdown-content {
    display: block;
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        align-items: flex-start;
    }

    .navbar-links {
        flex-direction: column;
        width: 100%;
    }

    .nav-item {
        margin: 10px 0;
    }
}

/* *********************************************************************** */




.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: all 0.3s;
}

.navbar-links {
    display: flex;
    list-style: none;
    gap: 20px;
}

.nav-item {
    cursor: pointer;
}

.dropdown-content {
    display: none;
}

.navbar-links.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-links.active .nav-item {
    margin: 10px 0;
}

@media (max-width: 768px) {
    .navbar-links {
        display: none;
    
    }

    .hamburger {
        display: flex;
        flex-direction: column;
    }
}