.home-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background: linear-gradient(to right, #e0f7fa, #fce4ec);
}

.home-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;

}

/* Text section */
.text-section {
    display: flex;
    flex-direction: row;
    gap: 200px;
    margin-bottom: 30px;


}

.main-header {
    width: 500px;
    height: 360.5px;
    margin-top: 130px;
    gap: 42px;
    /* opacity: 0px; */

}

.text-section h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
}

.text-section p {
    font-size: 1.2rem;
    color: #666;
}

.highlight {
    color: #d32f2f;
    /* Highlighted text color */
    font-weight: bold;
}

.join-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #d32f2f;
    color: #fff;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-size: 1rem;
}

.join-button:hover {
    background-color: #b71c1c;
}


.large-image img {
    width: 300px;
    height: 400px;
    width: 400px;
    height: 530px;
    top: 175px;
    left: 1147px;
    gap: 10px;
    /* opacity: 0px; */

}

.image-wrapper img {
    width: 500px;
    height: 500px;
    gap: 0px;
    /* opacity: 0px; */
}

/* About section */
.about-section {
    display: flex;
    flex-direction: row;
    gap: 100px;
    margin-top: 80px;


}

.about-section h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.about-section h1 {
    /* font-family: Lato; */
    font-size: 32px;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 584px;
    height: 48px;

}

.read-more-button {
    margin-top: 10px;
    padding: 8px 18px;
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-size: 1rem;
}

.read-more-button:hover {
    background-color: #b71c1c;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .home-section {
        padding: 30px 15px;
    }

    .home-section-content {
        max-width: 900px;
    }

    .text-section {
        flex-direction: column;
        align-items: center;
        gap: 40px;
        /* Reduce gap for better fit */
        margin-bottom: 20px;
    }

    .main-header {
        width: 100%;
        height: auto;
        margin-top: 50px;
        gap: 20px;
    }

    .text-section h1 {
        font-size: 2rem;
        /* Reduce font size for smaller screens */
    }

    .text-section p {
        font-size: 1rem;
        /* Reduce font size for smaller screens */
        text-align: center;
    }

    .join-button {
        padding: 8px 16px;
        font-size: 0.9rem;
        /* Adjust button size */
    }

    .large-image img {
        width: 250px;
        /* Reduce image width */
        height: auto;
        /* Maintain aspect ratio */
    }

    .image-wrapper img {
        width: 300px;
        /* Reduce image size */
        height: auto;
        /* Maintain aspect ratio */
    }

    /* About section adjustments */
    .about-section {
        flex-direction: column;
        align-items: center;
        gap: 40px;
        /* Adjust gap for smaller screens */
        margin-top: 50px;
        /* Reduce margin */
    }

    .about-section h2 {
        font-size: 1.8rem;
        /* Adjust heading size */
        text-align: center;
        /* Center align for better readability */
    }

    .about-section h1 {
        font-size: 28px;
        /* Adjust font size */
        width: 100%;
        /* Make it responsive */
        text-align: center;
        /* Center align text */
    }

    .read-more-button {
        padding: 6px 14px;
        font-size: 0.9rem;
        /* Adjust button size */
    }
}

@media (max-width: 768px) {
    .text-section {
        flex-direction: column;
        gap: 20px;
    }

    .text-section h1 {
        font-size: 2rem;
    }

    .text-section p {
        font-size: 1rem;
    }

    .large-image img,
    .image-wrapper img {
        max-width: 300px;
        height: auto;
    }

    .about-section h2 {
        font-size: 1.5rem;
    }

    .about-section h1 {
        font-size: 1.5rem;
        line-height: 1.2;
    }
}

@media (max-width: 480px) {
    .text-section h1 {
        font-size: 1.5rem;
    }

    .text-section p {
        font-size: 0.9rem;
        width: 250px;
    }

    .join-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    .read-more-button {
        padding: 6px 14px;
        font-size: 0.9rem;
    }

    .about-section h1 {
        font-size: 1.2rem;
    }

    .text-section {
        display: flex;
        flex-direction: column;
        width: 300px;
    }

    .about-section {
        flex-direction: column;
        gap: 20px;
        width: 300px;
    }
}

/* *********************************************************** */
/* Section container */
.causes-section {
    padding: 40px 20px;
    background-color: #f9f9f9;

}

.header {
    margin-left: 25px;
}

.header h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}

.header h2 {
    font-size: 40px;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;


}

/* Cards container */
.cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

/* Card styles */
.home-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    width: 23%;
    /* height: 430px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.card:hover {
    transform: translateY(-5px);
}

.card-image {
    width: 100%;
    height: 170px;
    object-fit: cover;
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.card-content-det {
    overflow: hidden;
    height: 150px;
}

.card-category {
    color: #d32f2f;
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 5px;
}

.card-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.card-description {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 15px;
}

.donate-button {
    background-color: #d32f2f;
    color: #fff;
    border: none;
    padding: 10px 15px;
    width: max-content;
    display: flex;
    font-size: 0.9rem;
    border-radius: 56px;
    cursor: pointer;
    align-items: center;
    justify-content: left;
    margin-top: 20px;
}

.donate-button:hover {
    background-color: #b71c1c;
}

/* Navigation buttons */
.navigation {
    display: flex;
    justify-content: center;
    margin-top: 20px;

}

.nav-button {
    background-color: #f1f1f1;
    border: none;
    color: #d32f2f;
    font-size: 1.5rem;
    margin: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.nav-button:hover {
    background-color: #ddd;

}

/* Responsive Design */
/* Small desktops and tablets (1024px and below) */
@media (max-width: 1024px) {
    .home-card {
        width: 30%;
        /* Adjust width for small desktops and larger tablets */
    }

    .header h2 {
        font-size: 36px;
        line-height: 44px;
    }
}

/* Tablets (768px and below) */
@media (max-width: 768px) {
    .home-card {
        width: 45%;
        /* Adjust width for tablets */
    }

    .header h2 {
        font-size: 32px;
        line-height: 40px;
    }
}

/* Mobile devices (425px and below) */
@media (max-width: 425px) {
    .home-card {
        width: 100%;
        /* Full width for mobile devices */
        margin-bottom: 20px;
        /* Add spacing between cards */
    }

    .header h2 {
        font-size: 24px;
        line-height: 32px;
    }
}

/* Very small mobile devices (320px and below) */
@media (max-width: 320px) {
    .header h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .header h4 {
        font-size: 16px;
        line-height: 24px;
    }

    .card-title {
        font-size: 1rem;
    }

    .card-description {
        font-size: 0.85rem;
    }

    .donate-button {
        padding: 8px 12px;
        font-size: 0.8rem;
    }
}

/* **************************************************************************** */
.testimonials {
    background-color: #1c1c1c;
    color: #ffffff;
    text-align: center;
    padding: 50px 20px;
    border-radius: 8px;
    width: 96%;
    height: 500px;
}

.section-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #e0e0e0;
    text-align: center;

}

.section-subtitle {
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-weight: bold;
}

.testimonial-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-nav {
    background-color: #f28e8e;
    border: none;
    color: #ffffff;
    font-size: 2rem;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    margin: 0 15px;
}

.testimonial-nav:hover {
    background-color: #d16767;
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.testimonial-name {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.testimonial-position {
    font-size: 1rem;
    color: #b0b0b0;
    margin-bottom: 15px;
}

.testimonial-text {
    font-size: 1rem;
    line-height: 1.6;
}

.pagination {
    margin-top: 20px;

}

.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s;
}

.dot.active {
    background-color: #f28e8e;
}


/* Responsive Design */
@media (max-width: 1024px) {
    .section-subtitle {
        font-size: 2rem;
        /* Adjust font size */
    }

    .testimonial-container {
        flex-direction: column;
        /* Stack vertically */
    }

    .testimonial-nav {
        font-size: 1.5rem;
        /* Decrease size of nav buttons */
        padding: 8px;
    }

    .profile-image {
        width: 80px;
        /* Adjust image size */
        height: 80px;
    }
}

@media (max-width: 768px) {
    .section-title {
        font-size: 1.25rem;
        /* Adjust font size */
    }

    .section-subtitle {
        font-size: 1.75rem;
        /* Adjust font size */
    }

    .testimonial-text {
        font-size: 0.9rem;
        /* Reduce font size */
    }

    .testimonial-nav {
        font-size: 1.2rem;
        padding: 6px;
    }

    .profile-image {
        width: 70px;
        /* Smaller image size */
        height: 70px;
    }
}

@media (max-width: 480px) {
    .testimonials {
        /* padding: 30px 15px; */
        width: 300px;
    }

    .section-title {
        font-size: 1rem;
        /* Smaller font */
    }

    .section-subtitle {
        font-size: 1.5rem;
        /* Smaller font */
    }

    .testimonial-text {
        font-size: 0.85rem;
        /* Smaller text */
    }

    .profile-image {
        width: 60px;
        /* Smaller profile image */
        height: 60px;
    }

    .testimonial-nav {
        font-size: 1rem;
        padding: 5px;
        margin: 0 10px;
    }

    .dot {
        height: 8px;
        /* Smaller dots */
        width: 8px;
    }
}

/* *********************************************************************************** */
.article-carousel {
    padding: 40px;
    background-color: #f9f9f9;
}

.carousel-heading {
    font-size: 1.5rem;
    color: #0c0b0b;
}

.carousel-subheading {
    font-size: 2.5rem;
    /* margin-bottom: 30px; */
    font-weight: bold;
    color: #333;
}

.article-cards-container {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    padding: 10px 0;
}

.article-cards-container::-webkit-scrollbar {
    display: none;
}

.article-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 310px;
    flex-shrink: 0;
    overflow: hidden;
}

.article-image {
    width: 100%;
    height: 170px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.article-info {
    padding: 15px;
}

.author-date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 0.9rem;
    color: #777;
}

.article-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #222;
}

.article-description {
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 15px;
}

.read-more-button {
    display: inline-flex;
    align-items: center;
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.9rem;
}

.read-more-button i {
    margin-left: 5px;
}

.carousel-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.control {
    background-color: #f1f1f1;
    border: none;
    color: #d32f2f;
    font-size: 1.5rem;
    margin: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.control:hover {
    background-color: #d16767;
}


/* Responsive Design */
@media (max-width: 1024px) {
    .carousel-heading {
        font-size: 1.25rem;
    }

    .carousel-subheading {
        font-size: 2rem;
    }

    .article-card {
        width: 270px;
    }

    .article-title {
        font-size: 1.1rem;
    }

    .read-more-button {
        font-size: 0.85rem;
        padding: 6px 10px;
    }
}

@media (max-width: 768px) {
    .article-carousel {
        padding: 30px 15px;
    }

    .carousel-heading {
        font-size: 1.2rem;
    }

    .carousel-subheading {
        font-size: 1.75rem;
    }

    .article-card {
        width: 220px;
    }

    .article-title {
        font-size: 1rem;
    }

    .article-description {
        font-size: 0.85rem;
    }

    .read-more-button {
        font-size: 0.8rem;
        padding: 5px 8px;
    }

    .control {
        font-size: 1.2rem;
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 480px) {
    .article-carousel {
        width: auto;
        height: auto;
        padding: 20px 10px;
    }

    .carousel-heading {
        font-size: 1rem;
    }

    .carousel-subheading {
        font-size: 1.5rem;
    }

    .article-card {
        width: 180px;
    }

    .article-title {
        font-size: 0.9rem;
    }

    .article-description {
        font-size: 0.75rem;
    }

    .read-more-button {
        font-size: 0.7rem;
        padding: 4px 6px;
    }

    .control {
        font-size: 1rem;
        width: 30px;
        height: 30px;
    }
}

/* ******************************************************************************************* */
/* ######################################## */
@media (max-width: 480px) {
    .Mandapam-main-page {
        width: 100%;
        /* border: solid red; */
    }
}